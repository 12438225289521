import HttpUtil from 'utils/http-util';
import { ROUTE_API } from 'utils/route-util';

const NOTIFICATION_API = {
  today: async () => {
    const res: INotification.Root[] = await HttpUtil.get(
      ROUTE_API.nofitcationToday
    );
    return res;
  },
  upcoming: async () => {
    const res: INotification.Root[] = await HttpUtil.get(
      ROUTE_API.notificationUpcoming
    );
    return res;
  },
  aniversary: async ({ status }: { status: string }) => {
    const res: INotification.Root[] = await HttpUtil.get(
      ROUTE_API.nofificationAniversary,
      {
        params: {
          status: status,
        },
      }
    );
    return res;
  },
  sendGift: async ({ id }: { id?: number }) => {
    const res: INotification.Root[] = await HttpUtil.post(
      ROUTE_API.notificationSendGift.replace(':id', `${id}`)
    );
    return res;
  },
  totalEventToday: async () => {
    const res: INotification.TotdalEventToday = await HttpUtil.get(
      ROUTE_API.noificationTotal
    );
    return res;
  },
};
export default NOTIFICATION_API;
