/* Route Path */
export const ROUTE_PATH = {
  root: '/',
  login: '/login',
  dashboard: '/dashboard',
  error: '/page-not-found',
  notification: {
    root: '/notification',
    anniversary: '/notification/aniversary',
    upcomings: '/notification/upcoming',
  },
  orders: {
    root: '/event',
    order: '/event/order',
    createOrder: '/event/order/create',
    updateBooking: '/event/order/update/:id',

    // Invoice
    previewInvoice: '/event/order/preview/:id',
    updateFinal: '/event/order/final-invoice/update/:id',

    // Payment
    payments: '/event/payment',
    updatePayment: '/event/payment/update/:id',

    // Quotations
    quotations: '/event/quotation',
    createQuotation: '/event/quotation/create',
    updateQuotation: '/event/quotation/update/:id',
    previewQuotation: '/event/quotation/preview/:id',

    // Expense
    expense: '/event/expense',
    updateExpense: '/event/expense/update/:id',
    expensePreview: '/event/expense/preview/:id',
  },
  customers: {
    root: '/customer',
    customer: '/customer/registered',
    createNewCustomer: '/customer/registered/create',
    updateCustomer: '/customer/registered/update/:id',
    potentialCustomer: '/customer/potential',
    createPotentialCustomer: '/customer/potential/create',
    updatePotentialCustomer: '/customer/potential/update/:id',
  },
  menus: {
    root: '/menu',
    package: '/menu/package',
    food: '/menu/food',
    createFood: '/menu/food/create',
    updateFood: '/menu/food/update/:id',
    createMenuPackage: '/menu/package/create',
    updateMenuPackage: '/menu/package/update/:id',
  },
  prepareGrocery: {
    root: '/grocery',
    createGrocery: '/grocery/create',
    updateGrocery: '/grocery/update/:id',
    previewGrocery: '/grocery/preview/:id',
  },
  inventories: {
    root: '/inventory',
    product: '/inventory/product',
    units: '/inventory/unit',
    categories: '/inventory/category',
    addInventory: '/inventory/update-stock/:id',
    inventoryReport: '/inventory/reports',
  },
  suppliers: {
    root: '/supplier',
    createNewSupplier: '/supplier/create',
    updateSupplier: '/supplier/update/:id',
  },
  users: {
    root: '/user',
    user: '/user/list',
    createNewUser: '/user/create',
    updateUser: '/user/update/:id',
    roles: '/user/role',
    createNewRole: '/user/role/create',
    updateRole: '/user/role/update/:id',
    permission: '/user/permission',
    createNewPermission: '/user/permission/create',
    updatePermission: '/user/permission/update/:id',
  },
  setting: {
    root: '/setting',
    company: '/setting/company-detail',
    exchangeRate: '/setting/exchange-rate',
    paymentMethods: '/setting/payment-method',
    evntType: '/setting/event-type',
    expenseCategory: '/setting/expense-category',
  },
};

/* Route Path API */
export const ROUTE_API = {
  baseUrl: process.env.REACT_APP_API_URL,
  // Auth
  login: '/v1/auth/login',
  refreshToken: '/api/auth/v1/refresh-token',

  // Orders
  ordersList: '/v1/order/list',
  orderCreate: '/v1/order/create/exist-customer',
  orderUpdate: '/v1/order/update/:id',
  orderDelete: '/v1/order/delete/:id',
  orderDetail: '/v1/order/:id',
  createFinalInvoice: '/v1/order/create/final-invoice/:id',
  orderCreateNewCustomer: '/v1/order/create/new-customer',

  // Quotations
  quotationList: '/v1/order/quotation/list',
  quotationCreate: '/v1/order/quotation/create',
  quotationUpdate: '/v1/order/quotation/update/:id',
  quotationDelete: '/v1/order/quotation/delete/:id',
  quotationDetail: '/v1/order/quotation/:id',

  // Payments
  paymentList: '/v1/order/payment/list',
  paymentUpdate: '/v1/order/payment/create/:id',
  paymentDelete: '/v1/order/payment/delete/:id',
  paymentDetail: '/v1/order/payment/:id',

  // Customer
  customersList: '/v1/customer/list',
  customerDetail: '/v1/customer/:id',
  addCustomer: '/v1/customer/create',
  updateCustomer: '/v1/customer/update/:id',
  deleteCustomer: '/v1/customer/delete/:id',

  // Potential Customer
  potentialCustomersList: '/v1/potential/list',
  potentialCustomerDetail: '/v1/potential/:id',
  potentialCustomerAdd: '/v1/potential/create',
  potentialCustomerUpdate: '/v1/potential/update/:id',
  potentialCustomerDelete: '/v1/potential/delete/:id',

  // Expense
  expenseList: '/v1/expense/list',
  expenseDetail: '/v1/expense/:id',
  expenseOrder: '/v1/expense/order/:id',
  expenseCreate: '/v1/expense/create',
  expenseUpdate: '/v1/expense/update/:id',
  expenseDelete: '/v1/expense/delete/:id',
  expenseOnList: '/v1/expense/expense-on/list',

  // Supplier
  suppliersList: '/v1/supplier/list',
  suppliersDetail: '/v1/supplier/:id',
  supplierUpdate: '/v1/supplier/update/:id',
  SupplierDelete: '/v1/supplier/delete/:id',
  supplierCreate: '/v1/supplier/create',

  // Notification
  nofitcationToday: '/v1/notification/today',
  notificationUpcoming: '/v1/notification/upcoming',
  nofificationAniversary: '/v1/notification/aniversary',
  notificationSendGift: '/v1/notification/aniversary/update/:id',
  noificationTotal: '/v1/notification/total',

  // Dashboard
  charts: '/v1/dashboard/charts',
  totals: '/v1/dashboard/orders',

  // Inventory
  stockReportList: '/v1/inventory/report/list',
  stockReportDetail: '/v1/inventory/report/:id',
  inventoryUpdateStock: '/v1/inventory/create',
  //
  unitList: '/v1/inventory/unit/list',
  unitDetail: '/v1/inventory/unit/:id',
  createUnit: '/v1/inventory/unit/create',
  updateUnit: '/v1/inventory/unit/update/:id',
  deleteUnit: '/v1/inventory/unit/delete/:id',
  //
  productList: '/v1/inventory/product/list',
  productListData: '/v1/inventory/product/list-product',
  productDetail: '/v1/inventory/product/:id',
  createProduct: '/v1/inventory/product/create',
  updateProduct: '/v1/inventory/product/update/:id',
  deleteProduct: '/v1/inventory/product/delete/:id',
  //
  stockCategoryList: '/v1/inventory/category/list',
  stockCategoryDetail: '/v1/inventory/category/:id',
  stockCreateCategory: '/v1/inventory/category/create',
  stockUpdateCategory: '/v1/inventory/category/update/:id',
  stockDeleteCategory: '/v1/inventory/category/delete/:id',

  // Prepare Grocery
  groceryList: '/v1/prepare-grocery/list',
  groceryCreate: '/v1/prepare-grocery/create',
  groceryUpdate: '/v1/prepare-grocery/update/:id',
  groceryDetail: '/v1/prepare-grocery/:id',
  groceryGenerate: '/v1/prepare-grocery/generate/:id',
  groceryDelete: '/v1/prepare-grocery/delete/:id',
  groceryValidation: '/v1/prepare-grocery/validation/:id',
  cutStockProduct: '/v1/inventory/cut-stock-product/:id',
  stockTotalProduct: '/v1/inventory/total-product',

  // User
  userList: '/v1/auth/user/list',
  addNewUser: '/v1/auth/user/create',
  updateUser: '/v1/auth/user/update/:id',
  deleteUser: '/v1/auth/user/delete/:id',
  userDetials: '/v1/auth/user/:id',

  // Role
  roles: '/v1/auth/role/list',
  createRole: '/v1/auth/role/create',
  updateRole: '/v1/auth/role/update/:id',
  roleDetial: '/v1/auth/role/:id',

  // Permission
  permissionList: '/v1/auth/permision/list',

  // Geography
  province: '/v1/setting/geography/province',
  district: '/v1/setting/geography/district/:id',
  commune: '/v1/setting/geography/commune/:id',

  // Menu
  menusList: '/v1/menus/list',
  menusCreate: '/v1/menus/create',
  menusUpdate: '/v1/menus/update/:id',
  menusDelete: '/v1/menus/delete/:id',
  menusDetail: '/v1/menus/:id',

  // Food
  foodList: '/v1/menus/food/list',
  foodCreate: '/v1/menus/food/create',
  foodUpdate: '/v1/menus/food/update/:id',
  foodDetail: '/v1/menus/food/:id',
  foodDelete: '/v1/menus/food/delete/:id',

  // Setting
  companyProfile: '/v1/setting/company-profile/:id',
  updateCompanyProfile: '/v1/setting/company-profile/update/:id',

  exchangeRateDetails: '/v1/setting/exchange-rate/:id',
  updateExchangeRate: '/v1/setting/exchange-rate/update/:id',

  paymentMethodsDetails: '/v1/setting/payment-method/:id',
  paymentMethodsList: '/v1/setting/payment-method/list',
  updatePaymentMethods: '/v1/setting/payment-method/update/:id',
  createPaymentMethods: '/v1/setting/payment-method/create',
  deletePaymentMethods: '/v1/setting/payment-method/delete/:id',

  eventTypeList: '/v1/setting/event-type/list',
  eventTypeDetials: '/v1/setting/event-type/:id',
  updateEventType: '/v1/setting/event-type/update/:id',
  createEventType: '/v1/setting/event-type/create',
  deleteEventType: '/v1/setting/event-type/delete/:id',

  expenseCategoryList: '/v1/expense/expense-on/list',
  expenseCategoryDetials: '/v1/expense/expense-on/:id',
  updateExpenseCategory: '/v1/expense/expense-on/update/:id',
  createExpenseCategory: '/v1/expense/expense-on/create',
  deleteExpenseCategory: '/v1/expense/expense-on/delete/:id',

  // upload image
  uploadImage: '/v1/upload-image/upload',
  getImage: '/v1/upload-image/',
};
