import { useRequest } from 'ahooks';
import NOTIFICATION_API from 'api/notification';
import useResponsive from 'hook/useResponsive';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getPersistedState, persistState } from 'utils/persist-util';

interface IDrawerContext {
  collapse: boolean;
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  totalNotification: INotification.TotdalEventToday | undefined;
}

export const DrawerContext = createContext<IDrawerContext>({
  collapse: false,
  openDrawer: false,
  setOpenDrawer() {},
  setCollapse() {},
  totalNotification: { total: 0 },
});

interface IAppWrapper {
  children: React.ReactNode;
}

export function DrawerWrapper({ children }: IAppWrapper) {
  // state
  const [openDrawer, setOpenDrawer] = useState(false);
  const [collapse, setCollapse] = useState(
    getPersistedState('collapse') || false
  );
  const { isMdDown } = useResponsive();

  // fetch notifaication
  const { data: totalNoti } = useRequest(NOTIFICATION_API.totalEventToday, {
    manual: false,
    cacheKey: 'total-notification',
  });

  useEffect(() => {
    persistState('collapse', collapse);
  }, [collapse]);

  useEffect(() => {
    isMdDown && setCollapse(false);
  }, [isMdDown]);

  return (
    <DrawerContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
        collapse,
        setCollapse,
        totalNotification: totalNoti,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export function useDrawerContext() {
  return useContext(DrawerContext);
}
