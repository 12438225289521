import {
  Avatar,
  Badge,
  Box,
  Collapse,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Popper,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { ArrowDown2, ArrowRight2, Component, LogoutCurve } from 'iconsax-react';
import { useDrawerContext } from 'context/DrawerContext';
import { motion } from 'framer-motion';
import { useAuthContext } from 'context/AuthContext';
import useRouter, { ROUTE_PATH } from 'hook/useRouter';
import navigationUtil from 'utils/navigation-util';
import THEME_UTIL from 'utils/theme-util';
import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';

const DrawerContent = () => {
  /* State */
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openItemChildren, setOpenItemChildren] = useState('');

  /* Hooks */
  const scrollRef = document.getElementById('sidebar-scroll');
  const { location, navigate } = useRouter();
  const { openDrawer, setOpenDrawer, collapse, totalNotification } =
    useDrawerContext();
  const { logout } = useAuthContext();
  const theme = useTheme();

  /* Methods */
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenItemChildren('');
  };

  useEffect(() => {
    setOpenItemChildren('');
  }, [collapse]);

  return (
    <>
      <Avatar
        variant='square'
        src={'/images/mongkol_serey_logo_1000.png'}
        alt='logo'
        imgProps={{
          sx: {
            objectFit: 'contain',
          },
        }}
        sx={{
          mx: 'auto',
          width: collapse ? 68 : 180,
          height: collapse ? 68 : 180,
        }}
      />
      <List
        sx={{
          height: collapse ? `calc(100vh - 60px)` : '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        disablePadding
      >
        {navigationUtil.map((nav) => {
          return (
            <React.Fragment key={nav.title}>
              <ListItem
                sx={{
                  py: 0.5,
                  px: 2,
                }}
                secondaryAction={
                  !collapse &&
                  nav.children.length > 0 &&
                  (matchPath(nav.toUrl, location.pathname) ? (
                    <IconButton disabled>
                      <ArrowDown2
                        size='16'
                        style={{
                          zIndex: 2,
                        }}
                        color={theme.palette.secondary.main}
                      />
                    </IconButton>
                  ) : (
                    <IconButton disabled>
                      <ArrowRight2 size='16' />
                    </IconButton>
                  ))
                }
              >
                <ListItemButton
                  sx={{
                    position: 'relative',
                    borderRadius: 2.5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={(e) => {
                    if (nav.children.length > 0) {
                      setOpenItemChildren(
                        nav.toUrl === openItemChildren ? '' : nav.toUrl
                      );
                    } else {
                      navigate(`${nav.toUrl}`);
                      setOpenItemChildren('');
                    }
                    openDrawer && collapse && setOpenDrawer(false);
                    collapse && handlePopoverOpen(e);
                    if (nav.toUrl === ROUTE_PATH.setting.root) {
                      setTimeout(() => {
                        scrollRef?.scrollTo({
                          top: 1000000,
                          behavior: 'smooth',
                        });
                      }, 300);
                    }
                  }}
                >
                  {matchPath(nav.toUrl, location.pathname) && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: THEME_UTIL.goldGradientMain,
                        zIndex: 0,
                        borderRadius: 2.5,
                        width: '100%',
                      }}
                      component={motion.div}
                      layoutId='selectedNav'
                    />
                  )}
                  <ListItemIcon
                    sx={{
                      minWidth: collapse ? 0 : undefined,
                      zIndex: 2,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    {location.pathname.includes(nav.toUrl) ? (
                      nav.selectedIcon
                    ) : nav.toUrl === ROUTE_PATH.notification.root ? (
                      <Badge
                        color='error'
                        variant='standard'
                        overlap='circular'
                        showZero={false}
                        badgeContent={
                          (totalNotification && totalNotification.total) || 0
                        }
                      >
                        {nav.icon}
                      </Badge>
                    ) : (
                      nav.icon
                    )}
                  </ListItemIcon>
                  <ListItemText
                    hidden={collapse ? true : false}
                    primary={
                      !collapse && (
                        <Typography
                          color={theme.palette.secondary.main}
                          variant='body2'
                          fontWeight={
                            location.pathname.includes(nav.toUrl)
                              ? 'bold'
                              : 'regular'
                          }
                        >
                          {nav.title}
                        </Typography>
                      )
                    }
                    primaryTypographyProps={{
                      fontWeight: 500,
                      color: location.pathname.includes(nav.toUrl)
                        ? 'common.white'
                        : 'secondary.main',
                    }}
                    sx={{
                      zIndex: 1,
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disableGutters disablePadding>
                <Collapse
                  in={!collapse && openItemChildren === nav.toUrl}
                  unmountOnExit
                  sx={{ width: '100%' }}
                >
                  <List disablePadding>
                    {nav.children.map((child, index) => {
                      return (
                        <ListItem
                          key={index}
                          sx={{ pl: 4, pr: 2, mb: 0.5 }}
                          disableGutters
                          disablePadding
                        >
                          <ListItemButton
                            sx={{
                              background:
                                location.pathname === child.toUrl
                                  ? alpha(theme.palette.primary.main, 0.1)
                                  : theme.palette.common.white,
                              borderRadius: 2.5,
                            }}
                            onClick={() => {
                              navigate(`${child.toUrl}`);
                              openDrawer && setOpenDrawer(false);
                            }}
                          >
                            <ListItemIcon>
                              <Component
                                size='12'
                                variant='Bold'
                                color={
                                  location.pathname === child.toUrl
                                    ? theme.palette.primary.main
                                    : theme.palette.secondary.main
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={child.title}
                              primaryTypographyProps={{
                                color:
                                  location.pathname === child.toUrl
                                    ? theme.palette.primary.main
                                    : theme.palette.secondary.main,
                                fontWeight:
                                  location.pathname === child.toUrl
                                    ? 'bold'
                                    : 'medium',
                                fontSize: 14,
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </ListItem>

              {collapse &&
                nav.children.length > 0 &&
                openItemChildren === nav.toUrl && (
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    sx={{
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                      pl: 2,
                      '&> .css-1a2qywp-MuiPaper-root': {
                        boxShadow: theme.shadows[0],
                        borderRadius: 2.5,
                      },
                    }}
                  >
                    <Paper
                      sx={{
                        height: 'auto',
                        border: `1px solid ${theme.palette.primary.main}`,
                        transition: 'border-color 2s ease-in-out',
                      }}
                    >
                      <List component='div' disablePadding>
                        {nav.children.map((child, index) => {
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton
                                sx={{
                                  background: matchPath(
                                    child.toUrl,
                                    location.pathname
                                  )
                                    ? alpha(theme.palette.primary.main, 0.1)
                                    : theme.palette.common.white,
                                  borderRadius: 2.5,
                                }}
                                onClick={() => {
                                  navigate(`${child.toUrl}`);
                                  openDrawer && setOpenDrawer(false);
                                  handlePopoverClose();
                                }}
                              >
                                <ListItemText
                                  primary={child.title}
                                  primaryTypographyProps={{
                                    color: matchPath(
                                      child.toUrl,
                                      location.pathname
                                    )
                                      ? theme.palette.primary.main
                                      : theme.palette.secondary.main,
                                    fontWeight: matchPath(
                                      child.toUrl,
                                      location.pathname
                                    )
                                      ? 'bold'
                                      : 'medium',
                                    fontSize: 14,
                                  }}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Paper>
                  </Popover>
                )}
            </React.Fragment>
          );
        })}

        {/* Logout Button */}
        <ListItem sx={{ py: 0.5 }}>
          <ListItemButton
            sx={{
              position: 'relative',
              borderRadius: 2,
            }}
            onClick={() => {
              logout();
              openDrawer && setOpenDrawer(false);
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: collapse ? 0 : undefined,
                zIndex: 2,
                color: theme.palette.error.main,
              }}
            >
              <LogoutCurve
                size='24'
                variant='Bold'
                style={{ position: 'relative', left: -2 }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                !collapse && (
                  <Typography
                    fontWeight={'medium'}
                    color={theme.palette.error.main}
                  >
                    Exit
                  </Typography>
                )
              }
              primaryTypographyProps={{
                fontWeight: 500,
                color: 'secondary.main',
              }}
              sx={{
                zIndex: 1,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export default DrawerContent;
